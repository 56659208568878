import React from 'react';
import { Button, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useToast } from '@chakra-ui/react';
import SocialLogin from './SocialLogin';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { usernameLogin } from '../api';

interface LoginModalProps {
    isOpen: boolean;
    onClose: () => void;
}

export default function LoginModal({isOpen, onClose}: LoginModalProps) {


    const initialRef = React.useRef(null)
    const finalRef = React.useRef(null)

    const toast = useToast();
    const queryClient = useQueryClient();

    const {register, handleSubmit, reset} = useForm();
    const mutation = useMutation(usernameLogin,{
        onMutate: () => {
            console.log('onMutate');
        },
        onSuccess: () => {
            toast({
                title: 'Login Success',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            onClose();
            queryClient.refetchQueries(['me']);
            reset();
        },
        onError: (error: any) => {
            toast({
                title: error.response.data.error,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        },

    });

    const onSubmit = ({username, password}:any) => {
        mutation.mutate({username, password});
    };

    return(
        <Modal
            initialFocusRef={initialRef}
            finalFocusRef={finalRef}
            isOpen={isOpen}
            onClose={onClose}
            isCentered
            motionPreset='slideInBottom'
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>LOGIN</ModalHeader>
                <ModalCloseButton />
                <ModalBody 
                    as='form' 
                    pb={6} 
                    w={'full'} 
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Input
                        required 
                        variant={'filled'} 
                        placeholder='Username' 
                        {...register('username', {
                            required: true
                        })}
                    />
                    <Input
                        required 
                        variant={'filled'} 
                        mt={4} pr='4.5rem' 
                        type={'password'} 
                        placeholder='Enter password' 
                        {...register('password',{
                            required: true
                        })}
                    />
                    {mutation.isError ? (
                        <p style={{color: 'red'}}>{mutation.error.response.data.error}</p>
                    ) : null}
                    <Button isLoading={mutation.isLoading} type='submit' mt={6} colorScheme='red' width='100%'>Login</Button>
                    <SocialLogin />
                </ModalBody>
            </ModalContent>
        </Modal>
    );    
}