import { Box, Button, Divider, HStack, Text, VStack } from '@chakra-ui/react';
import { FaGithub, FaComment } from "react-icons/fa6";

export default function SocialLogin() {

    const githubParams = new URLSearchParams({
        // Client secrets: b10f3fc3af5a09f7565dbb72f15387fadc36af1b
        client_id: 'd3c00b255dc4bacc7858',
        scope: 'read:user,user:email',
    });
    const kakaoParams = new URLSearchParams({
        response_type: 'code',
        client_id: '3ff55a0bf9aa2c0232315f08ecd29835',
        redirect_uri: 'http://127.0.0.1:3000/social/kakao',
    });
    const cafe24Params = new URLSearchParams({
        response_type: 'code',
        client_id: 'f8amqe9IeJoukpqvHxvhyO',
        redirect_uri: 'https://developer.thurpia01.com:3000/social/cafe24',
        scope: 'mall.read_application, mall.read_product, mall.write_product, mall.read_mileage, mall.write_mileage',

    });

    const githubLoginUrl = `https://github.com/login/oauth/authorize?${githubParams.toString()}`;
    const kakaoLoginUrl = `https://kauth.kakao.com/oauth/authorize?${kakaoParams.toString()}`;
    const cafe24LoginUrl = `https://thurpiadev.cafe24api.com/api/v2/oauth/authorize?${cafe24Params.toString()}`;
    
    return(
        <Box>
            <HStack my={3}>
                <Divider />
                <Text>or</Text>    
                <Divider />
            </HStack>  
            <VStack>
                <Button 
                    as="a" 
                    href={githubLoginUrl} 
                    leftIcon={<FaGithub/>} 
                    colorScheme='blackAlpha' 
                    width='100%'>Continue with Github
                </Button>
                {/* http://127.0.0.1:3000/social/github?code=5746a87b65dc417ca653 (클릭시 주소로 리다렉션) */}

                <Button 
                    as="a" 
                    href={kakaoLoginUrl} 
                    leftIcon={<FaComment/>} 
                    colorScheme='yellow' 
                    width='100%'>Continue with Kakao
                </Button>
                <Button 
                    as="a" 
                    href={cafe24LoginUrl}
                    leftIcon={<FaComment/>} 
                    colorScheme='yellow' 
                    width='100%'>Continue with Cafe24
                </Button>
            </VStack>   
        </Box>

    );
}