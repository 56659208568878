import { createBrowserRouter } from 'react-router-dom';
import Root from './components/Root';
import Home from './routes/Home';
import RoomPhoto from './routes/RoomPhoto';
import NotFound from './routes/NotFound';
import RoomDetail from './routes/RoomDetail';
import GithubConfirm from './routes/GithubConfirm';
import KakaoConfirm from './routes/KakaoConfirm';
import Cafe24Confirm from './routes/Cafe24Confirm';
import RoomUpload from './routes/RoomUpload';


const routes = createBrowserRouter([
    {
        path: '/',
        element: <Root />,              //루트 페이지    
        errorElement: <NotFound />,     //404 페이지
        children: [                     //자식 페이지
            {   
                path: '', 
                element: <Home />,
            },
            {   
                path: "rooms",
                children: [
                    {
                        path: ":roomPk",
                        element: <RoomDetail />,
                    },
                    {
                        path: "upload",
                        element: <RoomUpload />,
                    },
                    {
                        path: ":roomPk/photo",
                        element: <RoomPhoto />,
                    }
                    
                ]
            },
            
            {   
                path: "social",
                children: [
                    {
                        path: "github",
                        element: <GithubConfirm />,
                    },
                    {
                        path: "kakao",
                        element: <KakaoConfirm />,
                    },
                    {
                        path: "cafe24",
                        element: <Cafe24Confirm />,
                    },
                ]
            },
        ],
    }
]);

export default routes;