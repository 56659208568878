import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { Heading } from '@chakra-ui/react';
import { Image } from '@chakra-ui/react';
import { FaCamera, FaRegHeart } from "react-icons/fa6";
import { Link } from 'react-router-dom';


interface IRoom {
    pk: number;
    name: string;
    rating: number;
    price: number;
    country: string;
    city: string;
    imageUrl?: string;
    is_owner: boolean;
}

export default function Room({
    pk,
    name,
    rating,
    price,
    country,
    city,
    imageUrl,
    is_owner
}:IRoom) {

    const placeholderImage = 'https://via.placeholder.com/300x300.png?text=No%20Image';
    const src = imageUrl ?? placeholderImage;

    return(
        <Link to={`/rooms/${pk}`}>
            <Box position={'relative'}>
                <Image w='100%' aspectRatio={'1/1'} objectFit={'cover'} rounded={'xl'} src={src} alt="Segun Adebayo" />
                <VStack pt={3} px={0.5} align={'left'} spacing={1}>
                    <HStack justifyContent={'space-between'} fontSize={2}>
                    <Heading noOfLines={1} fontSize={'md'}>
                        {name}
                    </Heading>
                    <Text as="b" fontSize={'md'}>
                        ⭐{rating}
                    </Text>
                    </HStack>
                    <Text fontSize={'sm'}>
                        {country} 및 {city}
                    </Text>
                    <HStack justifyContent={'flex-start'}>
                    <Text size={'md'} fontWeight={'bold'}>
                        &#8361;{price} / night
                    </Text>
                    </HStack>
                </VStack>
                <Text position={'absolute'} top={2} right={2} cursor={'pointer'} >
                    {is_owner ? (
                        <Link to={`/rooms/${pk}/photo`}>
                            <FaCamera size={20} />
                        </Link>
                    ) : (
                        <FaRegHeart size={20} />
                    )}
                    
                </Text>
            </Box>
        </Link>
    );
}