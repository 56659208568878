import { Button, Heading, Text, VStack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { LinkIcon } from '@chakra-ui/icons'

export default function NotFound() {

    return(
        // 그라디언트 배경
        // <VStack bgGradient="linear(to-r, blue.200, pink.500)" minH="100vh" justifyContent={'center'}>
        <VStack minH="100vh" justifyContent={'center'}>
        <Heading>Page not found.</Heading>
            <Text>Sorry, we couldn't find the page you're looking for.</Text>
            <Link to="/">
                <Button leftIcon={<LinkIcon />} colorScheme="blue" onClick={() => window.history.back()}>Go back &rarr;</Button>
            </Link>
        </VStack>
    );
    
}