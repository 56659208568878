import { Heading, Spinner, Text, VStack } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { kakaoLogin } from '../api';
import { useToast } from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query";



export default function KakaoConfirm() {

    const toast = useToast();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    
    // URL의 쿼리 스트링을 가져온다.      
    const { search } = useLocation(); 

    // 쿼리 스트링에 code가 있으면 githubLogin 함수를 실행한다.
    const confirmLogin = async () => {

        // URLSearchParams는 쿼리 스트링을 다루는 객체이다.
        const params = new URLSearchParams(search);

        // 쿼리 스트링에서 code를 가져온다.
        const code = params.get('code');
        console.log(code);

        if (code) {
            const req = await kakaoLogin(code);
            console.log(req);
            if (req === 200) {
                toast({
                    title: '로그인 성공!',
                    description: "환영합니다.",
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })
                queryClient.refetchQueries(["me"]);
                navigate('/');
            } else {
                toast({
                    title: '로그인 실패',
                    description: "확인하세요",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }
        }

    };
    
    // useEffect는 컴포넌트가 렌더링 될 때마다 특정 작업을 수행하도록 설정할 수 있는 Hook이다.
    useEffect(() => {
        confirmLogin()
    }, []);

    return(
        // 그라디언트 배경
        // <VStack bgGradient="linear(to-r, blue.200, pink.500)" minH="100vh" justifyContent={'center'}>
        <VStack minH="100vh" justifyContent={'center'}>
            <Heading>Processing Logon...</Heading>
            <Text>Don't go anywhere</Text>
            <Spinner mt={4} size='xl' />
        </VStack>
    );
    
}