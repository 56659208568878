
import { Box } from '@chakra-ui/react';
import Header from './header';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'


export default function Root() {

    return(
        <Box>
            <Header />
            <ReactQueryDevtools initialIsOpen={false} />
        </Box>
    )

}