import { Grid } from '@chakra-ui/react';
import Room from '../components/Room';
import { useQuery } from '@tanstack/react-query';
import { getRooms } from '../api'
import RoomSkeleton from '../components/RoomSkeleton';

 interface IPhoto {
    pk: number;
    file: string;
    description: number;
}

interface IRoom {
    id: number;
    name: string;
    rating: number;
    price: number;
    country: string;
    city: string;
    photos: IPhoto[];
    is_owner: boolean;  
}


export default function Home() {

    const { isLoading, data } = useQuery<IRoom[]>(['rooms'], getRooms);
    console.log(data);

    const placeholderImage = 'https://via.placeholder.com/300x300.png?text=No%20Image';


    return(
        <Grid 
            templateColumns={{
                base: 'repeat(1, 1fr)',
                md: 'repeat(2, 1fr)',
                lg: 'repeat(3, 1fr)',
                xl: 'repeat(4, 1fr)',
                "2xl": 'repeat(6, 1fr)',
            }} 
            columnGap={6} rowGap={8} p={10} px={{
                base: 5,
                md: 10,
                lg: 20,
            }}>
            {isLoading ?(
                <>
                    <RoomSkeleton /> 
                    <RoomSkeleton /> 
                    <RoomSkeleton /> 
                    <RoomSkeleton /> 
                    <RoomSkeleton /> 
                    <RoomSkeleton /> 
                    <RoomSkeleton /> 
                    <RoomSkeleton /> 
                    <RoomSkeleton /> 
                    <RoomSkeleton /> 
                    <RoomSkeleton /> 
                    <RoomSkeleton /> 
                </>
            )
             : null}    
            {data?.map((room, index) => (
            <Room 
                key={index}
                pk={room.id}
                name={room.name}
                rating={room.rating}
                country={room.country}
                city={room.city}
                price={room.price}
                imageUrl={room.photos?.[0]?.file ?? placeholderImage}
                is_owner={room.is_owner}
            />
            ))}
            
        </Grid>
    );
}
/*
Hannam-dong, Yongsan-gu, South Korea
City views
₩301,028 
/ night
₩301,028 per night
·

₩2,408,226 total

Hannam-dong, Yongsan-gu, South Korea
River views
₩142,812 
/ night
₩142,812 per night
·

₩1,142,497 total
₩1,142,497 total
Show price breakdown
3.89


*/