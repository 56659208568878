import React from 'react';
import ReactDOM from 'react-dom/client';

import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { RouterProvider } from 'react-router-dom';

import routes from './router';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient()


const theme = extendTheme({
  colors: {
    mode: {
      light: {
        bg: '#FFFFFF',
        text: '#000000',
      },
      dark: {
        bg: '#1A202C',
        text: '#FFFFFF',
      },
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <RouterProvider router={routes} />
      </ChakraProvider>
    </QueryClientProvider>
);


