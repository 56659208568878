import { Controller, useForm } from 'react-hook-form';
import { Box, Button, Checkbox, Container, Flex, FormControl, FormLabel, Heading, Input, Select, Textarea,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    InputGroup,
    InputLeftAddon,
    FormHelperText,
    Grid,
    useToast,
    FormErrorMessage,
    Text,

} from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import useHostOnlyPage from '../hook/HostOnlyPage';
import { createRoom, getAmenity, getCategoryRoom } from '../api';
import { FaBarcode, FaToilet } from 'react-icons/fa6';
import { FaBed } from 'react-icons/fa';
import { get } from 'http';
import { IAmenity, ICategoryRoom } from '../types';


export default function RoomUpload() {

    useHostOnlyPage();

    const { handleSubmit, register } = useForm();
    const { data:categoryRoomData, isLoading:categoryRoomisLoading } = useQuery<ICategoryRoom[]>(['getCategoryRoom'], getCategoryRoom);  
    console.log(categoryRoomData);

    const { data:amenityData, isLoading:amenityisLoading } = useQuery<IAmenity[]>(['getAmenity'], getAmenity);  
    console.log(amenityData);

    const toast = useToast();

    const mutation = useMutation(createRoom, {
        onSuccess: (data) => {
            console.log("data");
            console.log(data);
            toast({
                title: "Room Upload Success",
                status: "success",
                duration: 9000,
                isClosable: true,
                position: "bottom-right",
            });
        },
        onError: (error: any) => {
            console.log("error.response");
            console.log(error.response.data);
            console.log(error.response.status);
            toast({
                title: "Room Upload Error",
                status: "error",
                duration: 9000,
                isClosable: true,
                position: "bottom-right",
            });
        },
    });

    const onSubmit = (data:any) => {
        mutation.mutate(data);
        console.log(data);
        // 여기에 데이터를 서버에 전송하는 로직을 작성할 수 있습니다.
    };




  return (
    <Box>
    <Container pt={10}>
        <Heading my={6} textAlign={'center'}>RoomUpload</Heading>
        <Box w='100%' as="form" onSubmit={handleSubmit(onSubmit)} p={5} borderWidth={1} borderRadius="md">
            <FormControl mb={4} >
                <FormLabel>Name</FormLabel>
                <Input type="text" {...register('name')} />
                <Text>{}</Text>
                {mutation.isError ? (
                <FormHelperText color={'red'}>{mutation.error.response.data.name}</FormHelperText>
                ) : null}
            </FormControl>
            <FormControl mb={4} >
                <FormLabel>Country</FormLabel>
                <Input type="text" {...register('country')} />
            </FormControl>
            <FormControl mb={4} >
                <FormLabel>City</FormLabel>
                <Input type="text" {...register('city')} />
            </FormControl>
            <FormControl mb={4} >
                <FormLabel>Address</FormLabel>
                <Input type="text" {...register('address')} />
            </FormControl>
            <FormControl mb={4} >
                <FormLabel>Price</FormLabel>
                <InputGroup>
                    <InputLeftAddon children="$USD" />
                    <Input type="number" {...register('price')} />
                </InputGroup>
            </FormControl>
            <FormControl mb={4} >
                <FormLabel>Rooms</FormLabel>
                <InputGroup>
                    <InputLeftAddon children={<FaBed />} />
                    <NumberInput min={1} max={5}>
                    <NumberInputField {...register('rooms')} />
                    <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                    </NumberInputStepper>
                    </NumberInput>
                </InputGroup>
            </FormControl>
            <FormControl mb={4} >
                <FormLabel>Toilets</FormLabel>
                <InputGroup>
                    <InputLeftAddon children={<FaToilet />} />
                    <NumberInput min={1} max={5}>
                    <NumberInputField {...register('toilets')} />
                    <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                    </NumberInputStepper>
                    </NumberInput>
                </InputGroup>
            </FormControl>
            <FormControl mb={4} >
                <FormLabel>Description</FormLabel>
                <Textarea {...register('description')} />
            </FormControl>
            <FormControl mb={4} >
                <FormLabel>Pet Friendly</FormLabel>
                <Checkbox {...register('pet_friendly')} />
            </FormControl>
            <FormControl mb={4} >
                <FormLabel>Kind</FormLabel>
                <FormHelperText>Select the kind of room</FormHelperText>
                <Select {...register('kind')}>
                    <option value="entire_place">Entire Place</option>
                    <option value="private_room">Private Room</option>
                    <option value="shared_room">Shared Room</option>
                </Select>
            </FormControl>
            <FormControl mb={4} >
                <FormLabel>Category</FormLabel>
                <FormHelperText>Select the Category</FormHelperText>
                <Select {...register('category')}>
                    {categoryRoomData?.map((category) => (
                    <option key={category.pk} value={category.pk}>
                        {category.name}
                    </option>
                    ))}
                </Select>
            </FormControl>
            <FormControl mb={4} >
                <FormLabel>Amenities</FormLabel>
                <FormHelperText>Check the Amenities</FormHelperText>
                <Grid templateColumns="repeat(2, 1fr)" gap={4}> 
                    {amenityData?.map((amenity) => (
                    <Box key={amenity.pk}>
                        <Checkbox key={amenity.pk} {...register('amenities')} value={amenity.pk}>
                            {amenity.name}
                        </Checkbox>
                        <FormHelperText>{amenity.description}</FormHelperText>
                    </Box>
                    ))}
                </Grid>
            </FormControl>
            <Button isLoading={mutation.isLoading} w={'full'} type="submit" mt={4} colorScheme="teal">Submit</Button>
            </Box>
    </Container>
    </Box>
  );
}