import React from 'react';
import { Button, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useEditable, useToast } from '@chakra-ui/react';
import SocialLogin from './SocialLogin';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { signUp } from '../api';

interface LoginModalProps {
    isOpen: boolean;
    onClose: () => void;
}

export default function SignUpModal({isOpen, onClose}: LoginModalProps) {

    const initialRef = React.useRef(null)
    const finalRef = React.useRef(null)

    const toast = useToast();
    const queryClient = useQueryClient();

    const { register, handleSubmit, reset } = useForm();
    const mutation = useMutation(signUp,{
        onMutate: () => {
            console.log('onMutate');
        },
        onSuccess: (data) => {
            console.log(data);
            toast({
                title: 'Login Success',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            onClose();
            queryClient.refetchQueries(['me']);
            reset();
        },
        onError: (error: any) => {
            console.log(error.response);
            console.log(error.response.status);
            toast({
                title: error.response.data.error,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        },

    });
    
    const onSubmit = ({username, email, password, name}:any) => {
        mutation.mutate({username, email, password, name});
        // console.log({username, email, password, name});
    };

    return(
        <Modal
            initialFocusRef={initialRef}
            finalFocusRef={finalRef}
            isOpen={isOpen}
            onClose={onClose}
            isCentered
            motionPreset='slideInBottom'
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>SIGNUP</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6} w={'full'} as='form'
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Input
                        required variant={'filled'} mt={4}  placeholder='Name'
                        {...register('name', {required: true})} 
                    />
                    <Input 
                        required variant={'filled'} mt={4} type='email' placeholder='Email' 
                        {...register('email', {required: true})}
                    />
                    
                    {mutation.isError ? (
                        <p style={{color: 'red'}}>{mutation.error.response.data.email}</p>
                    ) : null}
                    <Input 
                        required variant={'filled'} mt={4} placeholder='Username'
                        {...register('username', {required: true})} 
                    />
                    {mutation.isError ? (
                        <p style={{color: 'red'}}>{mutation.error.response.data.username}</p>
                    ) : null}
                    <Input 
                        required variant={'filled'} mt={4} pr='4.5rem' type='password' placeholder='Enter password'
                        {...register('password', {required: true})} 
                    />
                    <Button isLoading={mutation.isLoading} type='submit' mt={6} colorScheme='red' width='100%'>SignUp</Button>
                    <SocialLogin />
                </ModalBody>
            </ModalContent>
        </Modal>
    );    
}