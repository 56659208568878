import { Avatar, Menu, MenuButton, MenuItem, MenuList, useDisclosure, useQuery } from '@chakra-ui/react';

import { Box, Button, HStack, useColorMode, useToast } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { FaAirbnb, FaMoon, FaSun } from "react-icons/fa6";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from 'react-hook-form';

import LoginModal from './LoginModal';
import SignUpModal from './SignUpModal';
import useUser from '../lib/useUser';
import { logOut } from '../api';
import { AnyCnameRecord } from 'dns';


export default function Header() {

    const { userLoading, user, isLoggedIn } = useUser();
    console.log(user);
    const toast = useToast();

    //// 테마 모드
    const { colorMode, toggleColorMode } = useColorMode();
    const label = colorMode === 'dark' ? <FaSun /> : <FaMoon />;

    //// 로그인 모달
    const { isOpen:loginIsOpen, onOpen:loginOnopen, onClose:loginOnclose } = useDisclosure()
    const { isOpen:signUpIsOpen, onOpen:signUpOnOpen, onClose:signUpOnClose } = useDisclosure()

    //// 로그아웃
    const queryClient = useQueryClient();
    // * 로그아웃 함수

    const mutation = useMutation(logOut, {
        onSuccess: () => {
            toast({
                title: 'Logged out',
                description: "Done!",
                status: "success",
                duration: 5000,
                isClosable: true,
            })
            queryClient.refetchQueries(["me"]);
        },
        onError: (error: any) => {
            toast({
                title: error.response.data.error,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        },

    });
    const onLogOut = () => {
        mutation.mutate();
    }

    return(
        <Box>
            <HStack justifyContent={'space-between'} px={{ base: 5, md: 10, lg: 20 }} py={5} borderBottomWidth={1}>
                <Link to="/">
                    <FaAirbnb size={48} color='red'/>
                </Link>
                <HStack spacing={3}>
                    <Button onClick={toggleColorMode} colorScheme='blue' aria-label="Search database" >
                        {label}
                    </Button>
                    {
                    !userLoading ? (
                        !isLoggedIn ? (
                            <>
                            <Button onClick={loginOnopen}>Log in</Button>
                            <Button onClick={signUpOnOpen} colorScheme='red'>Sign up</Button>
                            </>
                            
                        ) : (
                            <Menu>
                                <MenuButton>
                                    <Avatar name={user?.username} src={user?.avatar} />
                                </MenuButton>
                                <MenuList>
                                    {user?.is_host ? (
                                    <Link to='/rooms/upload'>
                                        <MenuItem>Ceate Room</MenuItem>
                                    </Link>
                                    ) : (null)}
                                    <MenuItem onClick={onLogOut}>LogOut</MenuItem>
                                </MenuList>
                            </Menu>
                        )
                    ) : (null)
                    }
                </HStack>
            </HStack>
            <LoginModal isOpen={loginIsOpen} onClose={loginOnclose} />
            <SignUpModal isOpen={signUpIsOpen} onClose={signUpOnClose} />
            <Outlet />
        </Box>
    )

}