import Cookie from 'js-cookie';
import axios from 'axios';
import { QueryFunctionContext } from "@tanstack/react-query";

const inst = axios.create({
    baseURL: 
    process.env.NODE_ENV === "development"
        ? "http://127.0.0.1:8000/api/v1/"
        : "https://backend.kanstudiodevv.xyz/api/v1/",
    withCredentials: true, // 쿠키를 전달받기 위해 필요
})

// GET /api/v1/rooms/
export const getRooms = () => 
    inst.get('rooms/').then(res => res.data);

// GET /api/v1/rooms/:id/
export const getRoom = ({queryKey}: QueryFunctionContext) =>  
    inst.get(`rooms/${queryKey[1]}`).then(res => res.data);

// GET /api/v1/rooms/:id/reviews/
export const getRoomReviews = ({queryKey}: QueryFunctionContext) =>  
    inst.get(`rooms/${queryKey[1]}/reviews`).then(res => res.data);

// GET /api/v1/users/me
export const getMe = () => 
    inst.get('users/me').then((res) => res.data);

// POST /api/v1/users/login
export const logOut = () =>
    inst.post('users/log-out', null, {
        headers: {
            "X-CSRFToken": Cookie.get("csrftoken"),
        }
    }).then((res) => res.data);


// POST api/v1/users/github-login
export const githubLogin = (code: string) =>
    inst.post(
            'users/github-login', 
            { code },
            {
                headers: {
                    "X-CSRFToken": Cookie.get("csrftoken"),
                }
            }
    ).then(res => res.status);


// POST api/v1/users/kakao-login
export const kakaoLogin = (code: string) =>
    inst.post(
            'users/kakao-login', 
            { code },
            {
                headers: {
                    "X-CSRFToken": Cookie.get("csrftoken"),
                }
            }
    ).then(res => res.status);


// POST api/v1/users/log-in
export interface LoginData {
    username: string;
    password: string;
}
export const usernameLogin = ({username, password}:LoginData) =>
    inst.post(
            'users/log-in', 
            { username, password },
            {
                headers: {
                    "X-CSRFToken": Cookie.get("csrftoken"),
                }
            }
    ).then(res => res.data);

// POST api/v1/users/cafe24-login
export const cafe24Login = (code: string) =>
    inst.post(
            'users/cafe24-login', 
            { code },
            {
                headers: {
                    "X-CSRFToken": Cookie.get("csrftoken"),
                }
            }
    ).then(res => res.status);


// POST api/v1/users/sign-up
export interface SignUpData {
    username: string;
    email: string;
    password: string;
    name: string;
}
export const signUp = ({username, email, password, name}:SignUpData) =>
    inst.post(
            'users/', 
            { username, email, password, name },
            {
                headers: {
                    "X-CSRFToken": Cookie.get("csrftoken"),
                }
            }
    ).then(res => res.data);    


// POST api/v1/rooms/
export const createRoom = (data:any) =>
    inst.post(
            'rooms/', 
            data,
            {
                headers: {
                    "X-CSRFToken": Cookie.get("csrftoken"),
                }
            }
    ).then(res => res);    


// GET api/v1/categories/room
export const getCategoryRoom = () => 
    inst.get('categories/room').then(res => res.data);


// GET api/v1/categories/room
export const getAmenity = () => 
    inst.get('rooms/amenities').then(res => res.data);


// Cloud Flare Image Upload
export const getUploadUrl = ({filelist}:any) =>
    inst.post(
            'medias/photo/get-url', 
            filelist,
            {
                headers: {
                    "X-CSRFToken": Cookie.get("csrftoken"),
                    'Content-Type': 'multipart/form-data',  // 이 줄 추가
                }
            }
    ).then(res => res.data);   

export interface IUploadImage {
    file: File;
    uploadURL: string;
}


export const upLoadImage = ({file, uploadURL}:IUploadImage) =>{
    const formData = new FormData();     
    formData.append('file', file);   
    return axios.post(uploadURL, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    }).then(res => res.data);
}


export const createImage = ({roomPk, file, description}:any) =>
    inst.post(
            `rooms/${roomPk}/photo`, 
            {file, description},
            {
                headers: {
                    "X-CSRFToken": Cookie.get("csrftoken"),
                }
            }
    ).then(res => res);    


