import { Box, Button, Container, FormControl, FormLabel, Heading, Input, useToast
    , Grid, GridItem, Skeleton, Text, Stack, VStack, Image, HStack, Avatar, useDisclosure

} from '@chakra-ui/react';
import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import useHostOnlyPage from '../hook/HostOnlyPage';
import { createImage, getRoom, getUploadUrl, upLoadImage } from '../api';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';


export default function RoomPhoto() {

    useHostOnlyPage();

    const { roomPk } = useParams();
    const { handleSubmit, register, watch, reset } = useForm();
    const toast = useToast();
    const queryClient = useQueryClient();

    const { data:photosData, isLoading } = useQuery(['getRooms',roomPk], getRoom);
    
    const createImageMutaion = useMutation(createImage,{
        onSuccess(data) {
            toast({
                title: "Image Upload Success",
                status: "success",
                duration: 9000,
                isClosable: true,
                position: "bottom-right",
            });
            reset();
            queryClient.refetchQueries(['getRooms']);
        },

    })
    
    const upLoadMution = useMutation(upLoadImage,{
        onSuccess({result}) {
            createImageMutaion.mutate({
                file: `https://imagedelivery.net/SBbaUMdyQjsm6dK7i34s7g/${result.id}/public`,
                description: "I love react",
                roomPk,
            })
        },

    });

    const getUrlMutation = useMutation(getUploadUrl,{
        onSuccess: (data) => {
            data.forEach((uploadInfo:any, index:any) => {
                upLoadMution.mutate({
                    file: watch().file[index],
                    uploadURL: uploadInfo.uploadURL,
                });
            });
        },
    });


    const onSubmit = () => {
        const filelist = watch().file
        getUrlMutation.mutate({filelist});
    }

  return (
    <Box>
    <Container pt={10}>
        <Heading my={6} textAlign={'center'}>RoomUpload</Heading>
        <Box w='100%' as="form" p={5} borderWidth={1} borderRadius="md" onSubmit={handleSubmit(onSubmit)}>
            <FormControl isRequired>
                <FormLabel>Room Name</FormLabel>
                <Input 
                    placeholder="Image Upload" 
                    type='file'
                    multiple
                    accept='image/*'
                    {...register("file", { required: true })}
                />
            </FormControl>    
            <Button isLoading={
                        createImageMutaion.isLoading ||
                        upLoadMution.isLoading ||
                        getUrlMutation.isLoading ||
                        isLoading
                    } w={'full'} mt={4} colorScheme="teal" type="submit">Submit</Button>
        </Box>
        <Grid
            mt={8}
            templateRows='repeat(2, 1fr)'
            templateColumns='repeat(4, 1fr)'
            gap={2}
            rounded={'xl'}
            overflow={'hidden'}
            >
            {photosData?.photos.map((photo:any, index:any) => (
                <GridItem rowSpan={3} colSpan={1} key={index} >
                    <Skeleton isLoaded={!isLoading} w='100%' h='100%' >
                        <Image 
                            w='100%' 
                            aspectRatio={'717/600'} 
                            objectFit={'cover'} 
                            src={photo?.file} 
                            
                        />
                    </Skeleton>
                </GridItem>
            ))}
        </Grid>
        <Text>총 이미지 갯수 ({photosData?.photos?.length})</Text>
    </Container>
    </Box>
  );
}