import { useNavigate } from 'react-router-dom';
import useUser from '../lib/useUser';
import { useEffect } from 'react';


export default function useHostOnlyPage() {

    const { user, userLoading } = useUser();
    const navigate = useNavigate();

    useEffect(() => {
        if(!userLoading) {
            if (!user?.is_host) {
                navigate("/");
            }
        }

    }, [user, userLoading, navigate])

    return;
}