import { Stack, Skeleton } from '@chakra-ui/react';

export  default function RoomSkeleton() {
    return(
        <Stack>
            <Skeleton aspectRatio={'1/1'} rounded={'xl'} />
            <Skeleton rounded={'md'} w='100%' height='20px' />
            <Skeleton rounded={'md'} w='70%' height='20px' />
            <Skeleton rounded={'md'} w='50%' height='20px' />
        </Stack> 
    )
}