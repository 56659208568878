import { Box, Grid, GridItem, Skeleton, Text, Stack, Heading, VStack, Image, HStack, Avatar, Container } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { getRoom, getRoomReviews } from '../api'
import { useParams } from 'react-router-dom';
import { IReview, IRoomDetail } from '../types';



export default function RoomDetail() {

    const { roomPk } = useParams<{ roomPk: string }>();
    const { isLoading: roomLoding, data } = useQuery<IRoomDetail>(['rooms', roomPk], getRoom);
    const { isLoading: reviewsLoding, data: reviewData } = useQuery<IReview[]>(['rooms', roomPk, 'reviews'], getRoomReviews);

    const placeholderImage = 'https://via.placeholder.com/300x300.png?text=No%20Image';
    

    return(
        <Box display={'flex'} justifyContent={'center'} p={10} px={{
            base: 5,
            md: 10,
            lg: 20,
        }}>
            <Box width='1250px'>
                
                {roomLoding 
                ?(
                    <>
                        <Stack>
                            <Skeleton w='30%' height='45px' />
                        </Stack>
                    </>
                )
                : null} 
                
                <Heading>{data?.name}</Heading>
                <Grid
                    mt={8}
                    templateRows='repeat(2, 1fr)'
                    templateColumns='repeat(4, 1fr)'
                    gap={2}
                    rounded={'xl'}
                    overflow={'hidden'}
                    >
                    {Array.from({ length: 5 }).map((photo, index) => (
                        <GridItem rowSpan={index === 0 ? 2 : 1} colSpan={index === 0 ? 2 : 1} key={index} >
                            <Skeleton isLoaded={!roomLoding} w='100%' h='100%' >
                                <Image 
                                    w='100%' 
                                    aspectRatio={'717/600'} 
                                    objectFit={'cover'} 
                                    src={data?.photos[index]?.file ?? placeholderImage} 
                                />
                            </Skeleton>
                        </GridItem>
                    ))}
                </Grid>
                <VStack w={'60%'} mt={4} alignItems={'flex-start'}>
                    <Box py={8}>
                        <Heading fontSize={'2xl'}>{data?.city}, {data?.country}</Heading>
                        <Text fontSize={'lg'}>{data?.rooms} rooms · {data?.toilets} toilets</Text>
                        <HStack fontSize={'lg'} fontWeight={'medium'}>
                            <Text>⭐{data?.rating}</Text> · 
                            <Text textDecoration={'underline'}>{reviewData?.length}  Reviews</Text>
                        </HStack>
                    </Box>
                    <HStack spacing={4}>
                        <Avatar size={'md'} name={data?.owner.name} src={data?.owner.avatar} />
                        <VStack fontWeight={'medium'} spacing={0} alignItems={'flex-start'}>
                            <Text>Hosted by <Text as='b'>{data?.owner.name}</Text></Text>
                            <Text>{data?.address}</Text>
                        </VStack>
                    </HStack>
                    <Text fontSize={'lg'}>{data?.description}</Text>
                </VStack>
                <Grid   templateColumns='repeat(2, 1fr)'>
                    {reviewData?.map((review) => (
                        <GridItem  colSpan={1} key={review.id} py={8}>
                            <HStack>
                                <Avatar size={'md'} name={review.user.name} src={review.user.avatar} />
                                <VStack fontWeight={'medium'} spacing={0} alignItems={'flex-start'}>
                                    <Text>{review.user.name}</Text>
                                    <Text>{new Date(review.created_at).toLocaleString()}</Text>
                                </VStack>
                            </HStack>
                            <Text fontSize={'lg'}>{review.pay_load}</Text>
                        </GridItem>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
}